'use client';

import { useField } from 'formik';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';

import styles from './InboundV2FormikTextInput.module.css';
import { If } from '@/components/UtilitiesComponents';

interface InboundV2FormikTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  name: string;
  rightIcon?: IconsNames;
  maskFunction?: (value: string) => string;
  inputClassName?: CSSProperties;
  onBlurCallBack?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const InboundV2FormikTextInput: React.FC<InboundV2FormikTextInputProps> = ({
  label,
  description,
  maskFunction,
  rightIcon,
  onBlurCallBack,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasAutoFill, setHasAutoFill] = useState(false);
  const [field, meta, helpers] = useField(props.name);

  const activeLabel = useMemo(
    () => hasAutoFill || isFocused || field.value,
    [isFocused, field.value, hasAutoFill],
  );

  useEffect(() => {
    const input = document.getElementById(props.name) as HTMLInputElement;
    if (input) {
      setHasAutoFill(input.value !== '');
    }
  }, [props.name, field.value]);

  const getInputContainerClassNames = () => {
    return [
      styles.inputContainer,
      rightIcon ? styles.inputContainerHasRightIcon : '',
      isFocused ? styles.inputContainerFocused : '',
      meta?.error && meta?.touched ? styles.hasError : '',
    ]
      .filter(Boolean)
      .join(' ');
  };

  const handleInputChange = (event: { target: HTMLInputElement }) => {
    if (maskFunction) {
      helpers.setValue(maskFunction(event.target.value));
    } else {
      field.onChange(event);
    }
  };

  const handleInputOnBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (onBlurCallBack) onBlurCallBack(event);
    setIsFocused(false);
    field.onBlur(event);
  };

  return (
    <div>
      <div className={getInputContainerClassNames()}>
        <label
          htmlFor={props.name}
          className={`${styles.label} ${activeLabel ? styles.labelActive : ''}`}
        >
          {label}
        </label>
        <input
          {...field}
          {...props}
          id={props.name}
          type={props.type || 'text'}
          className={styles.input}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleInputOnBlur}
        />
        {rightIcon && <Icon className={styles.rightIcon} name={rightIcon} />}
      </div>

      <If condition={Boolean(description)}>
        <p className="mt-4 text-display-700 paragraph-14">{description}</p>
      </If>

      <If condition={Boolean(meta.error && meta.touched)}>
        <p
          id={`${props.name}-error`}
          className="mt-4 text-red-500 paragraph-14"
        >
          {meta.error}
        </p>
      </If>
    </div>
  );
};

export default InboundV2FormikTextInput;
